// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

//@import "simple-datatables/dist/style.css";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$angular-primary: mat.define-palette(mat.$indigo-palette);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$angular-theme: mat.define-light-theme((color: (primary: $angular-primary,
        accent: $angular-accent,
        warn: $angular-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($angular-theme);

* {

  font-family: Outfit, Verdana, Geneva, Tahoma, sans-serif;
  /* Color styles */
  --main-brand: rgba(0, 114, 206, 1);
  --neutral-50: rgba(255, 255, 255, 1);
  --neutral-100: rgba(248, 249, 251, 1);
  --neutral-200: rgba(237, 239, 245, 1);
  --neutral-300: rgba(232, 234, 241, 1);
  --neutral-400: rgba(223, 224, 231, 1);
  --neutral-500: rgba(195, 196, 205, 1);
  --neutral-600: rgba(163, 166, 183, 1);
  --neutral-700: rgba(114, 116, 125, 1);
  --neutral-800: rgba(63, 64, 71, 1);
  --neutral-900: rgba(44, 44, 48, 1);
  --blue-400: rgba(86, 153, 255, 1);
  --blue-300: rgba(114, 174, 255, 1);
  --blue-200: rgba(140, 196, 255, 1);
  --blue-100: rgba(185, 221, 255, 1);
  --blue-50: rgba(239, 248, 255, 1);
  --semantic-negative: rgba(219, 70, 70, 1);
  --semantic-lightgreen: rgba(82, 242, 98, 0.10000000149011612);
  --sematic-darkgreen: rgba(0, 115, 39, 1);

  /* Textsize styles  in rem*/
  /* base size: body1 (16px) */
  --h1: 6rem;
  --h2: 4rem;
  --h3: 3rem;
  --h4: 2rem;
  --h5: 1.5rem;
  --subtitle1: 1.12rem;
  --subtitle2: 1rem;
  --body1: 1rem;
  --body2: 0.88rem;
  --caption1: 0.75rem;
  --caption2: 0.75rem;
  --button1: 0.88rem;
  --button2: 1rem;
  --button3: 1.12rem;

  /* Paddings */
  --padding-xs: 4px;
  --padding-s: 8px;
  --padding-m: 16px;
  --padding-l: 24px;
  --padding-xl: 32px;


  /* Textsize styles in px*/
  --h1: 96px;
  --h2: 64px;
  --h3: 48px;
  --h4: 32px;
  --h5: 24px;
  --subtitle1: 18px;
  --subtitle2: 16px;
  --body1: 16px;
  --body2: 14px;
  --caption1: 12px;
  --caption2: 12px;
  --button1: 14px;
  --button2: 16px;
  --button3: 18px;

  /* Definitions to be accessed directly from template */
  .blue-text {
    color: var(--main-brand);
  }

  .blue-background {
    background-color: var(--main-brand) !important;
  }

  .default-page-background {
    background: var(--neutral-200);
  }

  .black-text {
    color: var(--neutral-900)
  }

  .white-text {
    color: var(--neutral-50);
  }

  .text-bold {
    font-family: UEFA-Bold, sans-serif, Verdana, Geneva, Tahoma;
  }

  .container-fluid {
    height: 100%;
    padding: 0;
  }

  .ng-select .ng-select-container {
    border: none;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 24px;
    min-height: 24px;
    font-size: 16px;
    color: var(--neutral-700);
  }

  .ng-arrow-wrapper {
    margin-top: 8px;
    margin-left: 16px;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: none !important;
    border-style: none !important;
    border-width: 0 !important;
  }

  .ng-select .ng-dropdown-panel {
    top: 33px;
    width: fit-content
  }

  .ng-select .ng-arrow-wrapper {
    background-image: url('/assets/img/chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;

    //margin-top: 2px;
    &.ng-select-opened {
      .ng-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .ng-select {
    &.ng-select-opened {
      .ng-arrow {
        transform: rotate(180deg);
      }
    }
  }
}

.rounded-container {
  border-radius: 30px;
}

.input-warn {
  border-color: red;
}

.diclamer-text {
  color: red
}

.snackbar-success {
  background-color: white;
  color: black;
}

.snackbar-error {
  background-color: red;
  color: white;
}

.pointer {
  cursor: pointer;
}

.carousel-indicators {
  display: none;
}