.tex-button-tertiary {
  background: var(--mainbrand, #0072CE);
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  padding: 10px 24px;
  color: var(--neutral-50, #FFF);
  font-family: UEFA-Regular, sans-serif, Verdana, Geneva, Tahoma;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: 0.036px;
}