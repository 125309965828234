
body {
    margin: 0;
    height: 100vh;
}

/* Animation */
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

.carousel-control-next {
    opacity: 1;
    right: -6%;
}

.carousel-control-prev {
    opacity: 1;
    left: -6%;
}

.carousel-control-next-icon {
    width: 3rem;
    height: 3rem;
    background-image: url('assets/img/CaretCircleRight.svg') !important;
    /* Esto oculta las flechas predeterminadas */
}

.carousel-control-prev-icon {
    width: 3rem;
    height: 3rem;
    background-image: url('assets/img/CaretCircleLeft.svg') !important;
    /* Esto oculta las flechas predeterminadas */
}

@media (max-width: 992px) {
    .carousel-control-next {
        opacity: 1;
        right: 30%;
        bottom: -95%;
    }

    .carousel-control-prev {
        opacity: 1;
        left: 30%;
        bottom: -95%;
    }
}